const textTypes = ['doc', 'docx', 'odt', 'rtf', 'txt'];
const audioTypes = ['mp3', 'wav', 'ogg', 'webm', 'm4a'];
const videoTypes = ['avi', 'mov', 'mp4', 'mpg', 'wmv'];
const thumbTypes = ['mov', 'mp4', 'wmv'];
const imageTypes = ['bmp', 'gif', 'jpg', 'png', 'svg', 'jpeg'];
const pageLayoutTypes = ['pdf'];
const spreadSheetTypes = ['xls', 'xlsx'];
const compresedFileTypes = ['7z', 'zip', 'rar', 'tar', 'gz'];
const dataFileTypes = ['csv', 'ppt', 'pptx'];
const textPreviewTypes = ['csv', 'txt', 'log'];
const officePreviewTypes = [
  'doc',
  'docx',
  'ppt',
  'pptx',
  'odt',
  'ods',
  'odp',
  ...spreadSheetTypes,
];
const otherFilesTypes = [
  'apk',
  'ipa',
  'exe',
  'mpeg',
  'wma',
  'flv',
  'm4a',
  'mkv',
  'psd',
];

const googlePreviewTypes = ['rtf'];

const appConstants = {
  version: '1.0.0',
  toastOptions: {
    autoClose: 5000, // miliseconds
    position: 'top-right',
  },
  noCam: 'no_cam',
  groupTypes: ['group', 'broadcast'],
  fileTypes: {
    textTypes,
    audioTypes,
    videoTypes,
    thumbTypes,
    imageTypes,
    pageLayoutTypes,
    spreadSheetTypes,
    compresedFileTypes,
    dataFileTypes,
    textPreviewTypes,
    officePreviewTypes,
    googlePreviewTypes,
    otherFilesTypes,
  },
  attendanceStatus: {
    first_half_present: { short_name: 'FHP', txt_color_1: '#e55353' },
    second_half_present: { short_name: 'SHP', txt_color_1: '#e55353' },
    Sunday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Second_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Forth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Fourth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    present: { short_name: 'PR', txt_color_1: '#2eb85c' },
    PR: { short_name: 'PR', txt_color_1: '#2eb85c' },
    AB: { short_name: 'AB', txt_color_1: '#e55352' },
    PH: { short_name: 'PH', txt_color_1: '#2b7b8a' },
    PH_: { short_name: 'PH-P', txt_color_1: '#2b7b8a' },
    PH_FHP: { short_name: 'PH-FHP', txt_color_1: '#2b7b8a' },
    PH_SHP: { short_name: 'PH-SHP', txt_color_1: '#2b7b8a' },
    FHP: { short_name: 'FHP', txt_color_1: '#e55353' },
    SHP: { short_name: 'SHP', txt_color_1: '#e55353' },
    WO: { short_name: 'WO', txt_color_1: '#5c5959' },
    WO_P: { short_name: 'WO-P', txt_color_1: '#5c5959' },
    WO_FHP: { short_name: 'WO-FHP', txt_color_1: '#5c5959' },
    WO_SHP: { short_name: 'WO-SHP', txt_color_1: '#5c5959' },
    PL: { short_name: 'PL', txt_color_1: '#0b429c' },
    CL: { short_name: 'CL', txt_color_1: '#03a9f4' },
    WL: { short_name: 'WL', txt_color_1: '#0b429c' },
    LW: { short_name: 'LW', txt_color_1: '#f79205' },
    CO: { short_name: 'CO', txt_color_1: '#fc8403' },
    TOD: { short_name: 'TOD', txt_color_1: '#fc8403' },
    C_Off: { short_name: 'C-off', txt_color_1: '#fc8403' },
    SSL: { short_name: 'SSL', txt_color_1: '#fc8403' },
    FH_PL: { short_name: 'FH-PL', txt_color_1: '#0b429c' },
    SH_PL: { short_name: 'SH-PL', txt_color_1: '#0b429c' },
  },
  shiftStatus: {
    "General shift  (09:30:00 - 18:30:00)":{ short_name: 'GS', txt_color_1: '#0b429c' },
    "Afternoon Shift (14:00:00 - 23:00:00)":{ short_name: 'AS', txt_color_1: 'red' },
  },
  validations: {
    minLength: 'Min $1 character required',
    maxLength: 'Max $1 character required',
    allFieldReq: 'All fields are required',
    fieldReq: '$FIELD is required',
    emailReq: 'Email id is required',
    emailInvalid: 'Email address invalid',
    inavlidInOutTime: 'Check In should be less than Check Out time',
  },
  accountStatus: [
    { id: 1, label: 'Active', value: 'active' },
    { id: 2, label: 'Inactive', value: 'inactive' },
  ],
  company:{
    name:"QDegrees",
    logo:'images/qdegreesLogo.png'
  },
  pageLimit:10,
  gender: [
    {id: 1, label:"Male",value:"M"},
    {id: 2, label:"Female",value:"F"},
    {id: 3, label:"Other",value:"Other"}
  ],
  month: [
    { id: 1, label: 'One', value: '1'},
    { id: 2, label: 'Two', value: '2'},
    { id: 3, label: 'Three', value: '3'},
    { id: 4, label: 'Four', value: '4'},
    { id: 5, label: 'Five', value: '5'},
    { id: 6, label: 'Six', value: '6'},
    { id: 7, label: 'Seven', value: '7'},
    { id: 8, label: 'Eight', value: '8'},
    { id: 9, label: 'Nine', value: '9'},
    { id: 10, label: 'Ten', value: '10'},
    { id: 11, label: 'Eleven', value: '11'},
    { id: 12, label: 'Twelve', value: '12'},
  ],
  otpExpireTime: 15,
  commercialAttributes : [
    { id: 1, label: 'Experience', value: 'experience'},
    { id: 2, label: 'Location', value: 'location'},
  ],
  commercialAttributesType :[
    {id:1, label:"Dropdown",value:'select'},
    {id:2, label:"Number",value:'number'},
    {id:3, label:"Text",value:'text'}
  ],

    subCommercialRowAttributes :[
      { label: "Amount", field_type: "number", lookup: "" },
      { label: "No of Employee", field_type: "number", lookup: "" },
    ],
    feedbackWorkLimit :{
      description:250,
      title:100
    },
    warningWordLimit: {
      reason:200
    },
    info :{
      warningLetter:"If you click on ADD button you can select different variables from the dropdown which wil add in $1. eg:- Tailgating #EMPNAME#(#EMPID#) Tailgating - Test(QD0000)"
    },
    reportType : [
      { name: "Department", value: "department" },
      { name: "Project Manager", value: "projectManager" },
      { name: "HOD", value: "HOD" },
      { name: "Reporting Manager", value: "reportManager" },
  ]
};
export default appConstants;
