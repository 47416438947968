// import { all, call, put, select, take } from 'redux-saga/effects';
import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { commonActions as actions, commonService } from './index';

function* handleGetSampleRequest() {}
function* handleMessageNotification() {
  let res;
  try {
    res = yield call(commonService.getMessageNotificationApiFun);

    if (res.isError) {
    } else {
      yield put(actions.handleMessageNotificationSuccess(res));
    }
  } catch (error) {
    //   yield put(actions.getAttendanceBasicDetailsError());
  }
}

function* handleNotification() {
  const action = yield take(
    actions.handleNotificationRequest.getType(),
  );
  let res;
  const dataParams = action.payload;
  try {
    res = yield call(commonService.getNotificationApi,dataParams);

    if (res.isError) {
    } else {
      yield put(actions.handleNotificationSuccess(res));
    }
  } catch (error) {
    //   yield put(actions.getAttendanceBasicDetailsError());
  }
}


  /* get Notification Sagas*/
  function* handleGetNotificationRequest() {
    const action = yield take(actions.getNotificationRequest.getType());
    const dataParams = action.payload;
    let res;
    try {
      res = yield call(commonService.getNotificationApi,dataParams);
      if (res.isError) {
        yield put(actions.getNotificationError(res));
      } else {
        yield put(actions.getNotificationSuccess(res));
      }
    } catch (error) {
      yield put(actions.getNotificationError());
    }
  }

function* handleMessageNotificationWatcher() {
  yield takeEvery(
    actions.getNotificationRequest,
    handleMessageNotification,
  );
}
function* handleNotificationWatcher() {
  yield takeEvery(
    actions.handleNotificationRequest,
    handleGetNotificationRequest,
  );
}

function* handleGetNotificationWatcher() {
  yield takeEvery(
    actions.handleNotificationRequest,
    handleNotification,
  );
}

function* rootCommonSaga() {
  yield all([
    handleGetSampleRequest(),
    handleMessageNotification(),
    handleMessageNotificationWatcher(),
    handleNotificationWatcher(),
    handleNotification(),

    handleGetNotificationRequest(),
    handleGetNotificationWatcher()
  ]);
}

export default rootCommonSaga;
