import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);

const DonutChart = ({ data }) => {
  let option = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: '',
      align: 'left',
    },
    subtitle: {
      text: '',
      align: 'left',
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 40,
        size: 230,
      },
    },
    series: [
      {
        name: 'Performance',
        data: data ? data : [],
      },
    ],
  };
  return <HighchartsReact 
  highcharts={Highcharts} 
  options={option} />;
};

export default DonutChart;
